import { createRouter, createWebHashHistory } from 'vue-router'
import storage from 'store'

const routes = [
    {path: '/', redirect: '/home' },
    {
        path: '/home',
        name: 'Home',
        component: () => import( /* webpackChunkName: "finished" */ './pages/home'),
        meta: { title: '快帮收银系统 -- 收银台' }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import( /* webpackChunkName: "finished" */ './pages/login'),
        meta: { title: '快帮收银系统 -- 登录' }
    },
    {
        path: '/order',
        name: 'Order',
        component: () => import( /* webpackChunkName: "finished" */ './pages/order'),
        meta: { title: '快帮收银系统 -- 账单列表' }
    },
    {
        path: '/order/detail',
        name: 'OrderDetail',
        component: () => import( /* webpackChunkName: "finished" */ './pages/order/detail'),
        meta: { title: '快帮收银系统 -- 账单详情' }
    },
    {
        path: '/stock/list',
        name: 'StockList',
        component: () => import( /* webpackChunkName: "finished" */ './pages/stock'),
        meta: { title: '快帮收银系统 -- 商品/库存' }
    },
    {
        path: '/stock/in',
        name: 'StockIn',
        component: () => import( /* webpackChunkName: "finished" */ './pages/stock/in'),
        meta: { title: '快帮收银系统 -- 采购进货单' }
    },
    {
        path: '/stock/check',
        name: 'StockCheck',
        component: () => import( /* webpackChunkName: "finished" */ './pages/stock/check'),
        meta: { title: '快帮收银系统 -- 库存盘点单' }
    },
    {
        path: '/statis',
        name: 'Statistics',
        component: () => import( /* webpackChunkName: "finished" */ './pages/statis'),
        meta: { title: '快帮收银系统 -- 数据概览' }
    },
    {
        path: '/print',
        name: 'Print',
        component: () => import( /* webpackChunkName: "finished" */ './pages/print'),
        meta: { title: '快帮收银系统 -- 打印小票' }
    },
]

const router = createRouter({
    // history: createWebHistory(),
    history: createWebHashHistory(),
    routes
})
// 登录页
const loginRoutePath = '/login'
// 白名单
const whiteList = [loginRoutePath] // no redirect whitelist
// 默认路由地址
const defaultRoutePath = '/'

router.beforeEach((to, from, next) => {
    const title = to.meta && to.meta.title;
    if (title) {
        document.title = title;
    }
    /* has token */
    if (storage.get('Access-Token')) {
        // 已登录情况下访问登录页 - 跳转到默认页
        if (to.path === loginRoutePath) {
            next({ path: defaultRoutePath })
        } else {
            next()
        }
    } else {
        if (whiteList.includes(to.path)) {
            // 在免登录白名单，直接进入
            next()
        } else {
            next({ path: loginRoutePath, query: { redirect: to.fullPath } })
        }
    }
})

export default router