import { createApp } from 'vue'
import App from './App'
import router from './router'
import storage from 'store'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'

import Print from 'vue3-print-nb'

const app = createApp(App)
app.directive('focus', {
    // 当被绑定的元素挂载到DOM中时
    mounted (el) {
        // 聚焦元素
        el.focus()
    }
})
app.config.globalProperties.$storage = storage

app.use(router)
// app.use(ElementPlus)
app.use(Antd)
app.use(Print)

app.config.globalProperties.$message.config({
    top: '200px'
})

app.mount('#app')

// createApp(App).mount('#app')

//已绑定的弹窗类列表
// app.config.globalProperties.$message = components.message;
// app.config.globalProperties.$notification = components.notification;
// app.config.globalProperties.$info = components.Modal.info;
// app.config.globalProperties.$success = components.Modal.success;
// app.config.globalProperties.$error = components.Modal.error;
// app.config.globalProperties.$warning = components.Modal.warning;
// app.config.globalProperties.$confirm = components.Modal.confirm;
// app.config.globalProperties.$destroyAll = components.Modal.destroyAll;